/* Animations */

/* Fade In */
@mixin fade_in($duration: 0.5s, $timing: ease-in-out) {
  animation: fade_in $duration $timing both;
}

@keyframes fade_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Fade Out */
@mixin fade_out($duration: 0.5s, $timing: ease-in-out) {
  animation: fade_out $duration $timing both;
}

@keyframes fade_out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Slide In Up */
@mixin slide_in_up($duration: 0.5s, $timing: ease-in-out) {
  animation: slide_in_up $duration $timing both;
}

@keyframes slide_in_up {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Slide Out Down */
@mixin slide_out_down($duration: 0.5s, $timing: ease-in-out) {
  animation: slide_out_down $duration $timing both;
}

@keyframes slide_out_down {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(20px);
    opacity: 0;
  }
}

/* Subtle Pulse */
@mixin subtle_pulse($duration: 1.5s, $timing: ease-in-out) {
  animation: subtle_pulse $duration $timing infinite;
}

@keyframes subtle_pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}
