.matrix\[page\] {

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: $screen_lg) {
      flex-direction: row;
    }

    @media (min-width: $screen_xl) {
      gap: 56px;
    }
  }

  .site_header {
    width: 100%;
    top: 0;
    flex: 1 1 0%;
    background: var(--bg_secondary);

    @media (min-width: $screen_lg) {
      background: var(--bg_secondary);
      position: sticky;
      height: 100vh;
      overflow-y: auto;
      min-width: 300px;
      max-width: 300px;
    }

    @media screen and (min-width: $screen_xl) {
      max-width: 320px;
    }
    
    @media (min-width: $screen_xxl) {
      max-width: 514px;
    }
  }

  .page {
    max-width: 820px;
    flex: 1 1 0%;
    @include pb(10);

    @media screen and (min-width: $screen_lg) {
      @include mt(5);
      max-width: calc(100% - 356px);
    }

    @media screen and (min-width: $screen_xl) {
      max-width: 820px;
    }

    .-last_updated {
      @include mt(10);
      color: var(--caption_color);
      font-family: var(--font_code);
      @include small_text_small;
    }

  }


  .page_content {
    padding-left: $container_padding;
    padding-right: $container_padding;
  }


  .page_title {
    margin-top: $spacing_lg;
    margin-bottom: $spacing_md;
    font-size: var(--h1_font_size_large);
    line-height: var(--h1_line_height_large);
    letter-spacing: var(--h1_letter_spacing_large);
    color: var(--font_primary);

    span {
      display: block;
      @include body_text_small;
      @include code_editor_text;
      color: var(--font_secondary);
    }

  }

  .footer_message {
    @include mt(20);
    color: var(--caption_color);
    font-family: var(--font_code);
    @include small_text_small;
    padding-left: $container_padding;
    padding-right: $container_padding;

    a:hover {
      color: var(--font_primary);
      text-decoration: underline;
    }
  }
  
  .link_copied {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    background: var(--code_editor_bg);
    width: 120px;
    border-radius: 4px;
    @include footer_text;
    color: var(--code_editor_text_color);
    text-align: center;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
  }
}