.search_bar {
  width: 100%;
  
  @media screen and (min-width: $screen_md) {
    width: auto;
    flex-grow: 1;
  }

  .-input_wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  .-icon_button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .-search_icon {
    width: 24px;
    height: 24px;
    fill: var(--font_accent);
  }

  .-search_input {
    width: 100%;
    padding: 10px 10px 10px 40px;
    border: 0;
    border-radius: 4px;
    background-color: var(--bg_secondary);
    color: var(--font_accent);
    @include code_editor_text;
    @include body_text_small;
  }

  .-search_input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
  }

  .-search_input::placeholder {
    color: #999;
  }
}