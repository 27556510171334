/* Colors Mixins */

/* Background Colors */
@mixin bg_primary {
  background-color: var(--bg_primary);
}

@mixin bg_secondary {
  background-color: var(--bg_secondary);
}

@mixin bg_accent {
  background-color: var(--bg_accent);
}

/* Code Editor Backgrounds */
@mixin code_editor_bg {
  background-color: var(--code_editor_bg);
}

@mixin code_editor_bg_alt {
  background-color: var(--code_editor_bg_alt);
}

/* Font Colors */
@mixin font_primary {
  color: var(--font_primary);
}

@mixin font_secondary {
  color: var(--font_secondary);
}

@mixin font_accent {
  color: var(--font_accent);
}

@mixin font_primary_hover {
  color: var(--font_primary_hover);
}

/* Border Colors */
@mixin border_default {
  border-color: var(--border_default);
}

@mixin border_hover {
  border-color: var(--border_hover);
}

/* Line Colors */
@mixin line_color {
  border-color: var(--line_color);
}

@mixin line_hover_color {
  border-color: var(--line_hover_color);
}

/* Code Editor View */
@mixin code_editor_text_color {
  color: var(--code_editor_text_color);
}

@mixin code_highlight_bg {
  background-color: var(--code_highlight_bg);
}

@mixin code_comment_color {
  color: var(--code_comment_color);
}

@mixin code_keyword_color {
  color: var(--code_keyword_color);
}

@mixin code_string_color {
  color: var(--code_string_color);
}

@mixin code_function_color {
  color: var(--code_function_color);
}

@mixin code_error_color {
  color: var(--code_error_color);
}

@mixin code_warning_color {
  color: var(--code_warning_color);
}

@mixin code_selection_bg {
  background-color: var(--code_selection_bg);
}

@mixin code_line_number_color {
  color: var(--code_line_number_color);
}

@mixin code_cursor_color {
  color: var(--code_cursor_color);
}

/* Functional Colors (Captions, Credits, Labels) */
@mixin caption_color {
  color: var(--caption_color);
}

@mixin label_color {
  color: var(--label_color);
}

/* Applying Mixins to HTML Elements for Example */
body {
  @include bg_primary;
  @include font_primary;
}

h1, h2, h3, h4, h5, h6 {
  @include font_secondary;
}

a {
  @include font_accent;
  
  &:hover {
    @include font_primary_hover;
  }
}

.border {
  @include border_default;
  
  &:hover {
    @include border_hover;
  }
}

.line {
  @include line_color;
  
  &:hover {
    @include line_hover_color;
  }
}

.code_editor {
  @include code_editor_bg;
  @include code_editor_text_color;
}

.code_editor .highlight {
  @include code_highlight_bg;
}

.code_editor .comment {
  @include code_comment_color;
}

.code_editor .keyword {
  @include code_keyword_color;
}

.code_editor .string {
  @include code_string_color;
}

.code_editor .function {
  @include code_function_color;
}

.code_editor .error {
  @include code_error_color;
}

.code_editor .warning {
  @include code_warning_color;
}

.code_editor .selection {
  @include code_selection_bg;
}

.code_editor .line_number {
  @include code_line_number_color;
}

.code_editor .cursor {
  @include code_cursor_color;
}

.caption, .credit {
  @include caption_color;
}

.label {
  @include label_color;
}
