.document {
  // General Spacing for all direct children
  > * {
    margin-bottom: $spacing_lg;
  }
  
  // Specific Spacing Rules
  p {
    margin-bottom: $spacing_md;

    // Increase spacing after first paragraph
    &:first-of-type {
      margin-top: 0;
      margin-bottom: $spacing_lg;
    }

    // Spacing between consecutive paragraphs
    & + p {
      margin-top: $spacing_sm;
    }
  }


  .-anchor {
    position: relative;
    cursor: pointer;

    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="UTF-8"%3F><svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg"><path d="m598.5 810c16.875 16.875 16.875 44.25 0 61.125l-151.5 151.5c-77.25 77.25-203.26 77.25-280.5 0s-77.25-203.26 0-280.5l252.37-252.37c77.25-77.25 203.26-77.25 280.5 0 14.25 14.25 26.625 30.75 36 48.75 10.875 21 3 47.25-18.375 58.125-21 10.875-47.25 2.625-58.125-18.375-5.25-10.125-12-19.5-20.25-27.75-43.5-43.5-114.75-43.5-158.26 0l-252.37 252.37c-43.5 43.5-43.5 114.75 0 158.26 43.5 43.508 114.75 43.5 158.26 0l151.5-151.5c16.875-16.875 44.25-16.875 61.125 0zm154.5-633-153 153c-16.875 16.875-16.875 44.25 0 61.125s44.25 16.875 61.125 0l153-153c43.5-43.5 114.75-43.5 158.26 0 43.508 43.5 43.5 114.75 0 158.26l-252.37 252.37c-43.5 43.5-114.75 43.5-158.26 0-7.125-7.125-13.5-15.375-18.375-24.375-11.625-21-38.25-28.125-58.875-16.5s-28.125 38.25-16.5 58.875c9 15.75 19.875 30.375 32.625 43.125 77.25 77.25 203.26 77.25 280.5 0l252.37-252.37c77.25-77.25 77.25-203.26 0-280.5-77.25-77.242-203.26-77.25-280.5 0z" fill="%232a4977"/></svg>');
      background-size: cover;
      width: 22px;
      height: 22px;
      color: var(--bg_accent);
      @include code_editor_text;
      visibility: hidden;
      
      @media screen and (min-width: $screen_lg) {
        margin-left: -30px;
      }
    }

    &:hover::before {
      @media screen and (min-width: $screen_lg) {
        visibility: visible;
      }
    }
  }

  h2.-heading {

    &::before {
      top: 30%;
      transform: translateY(-30%);
    }
  }

  h3.-anchor {
    position: relative;
    cursor: pointer;

    &::before {
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
    }
  }

  .-sr_only {
    display: none;
  }

  .-heading_link {
    opacity: 0;
    position: absolute;
    background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="UTF-8"%3F><svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg"><path d="m598.5 810c16.875 16.875 16.875 44.25 0 61.125l-151.5 151.5c-77.25 77.25-203.26 77.25-280.5 0s-77.25-203.26 0-280.5l252.37-252.37c77.25-77.25 203.26-77.25 280.5 0 14.25 14.25 26.625 30.75 36 48.75 10.875 21 3 47.25-18.375 58.125-21 10.875-47.25 2.625-58.125-18.375-5.25-10.125-12-19.5-20.25-27.75-43.5-43.5-114.75-43.5-158.26 0l-252.37 252.37c-43.5 43.5-43.5 114.75 0 158.26 43.5 43.508 114.75 43.5 158.26 0l151.5-151.5c16.875-16.875 44.25-16.875 61.125 0zm154.5-633-153 153c-16.875 16.875-16.875 44.25 0 61.125s44.25 16.875 61.125 0l153-153c43.5-43.5 114.75-43.5 158.26 0 43.508 43.5 43.5 114.75 0 158.26l-252.37 252.37c-43.5 43.5-114.75 43.5-158.26 0-7.125-7.125-13.5-15.375-18.375-24.375-11.625-21-38.25-28.125-58.875-16.5s-28.125 38.25-16.5 58.875c9 15.75 19.875 30.375 32.625 43.125 77.25 77.25 203.26 77.25 280.5 0l252.37-252.37c77.25-77.25 77.25-203.26 0-280.5-77.25-77.242-203.26-77.25-280.5 0z" fill="%232a4977"/></svg>');
    background-size: cover;
    color: var(--code_editor_bg);
    @include code_editor_text;
    margin-left: -28px;
    width: 28px;
    height: 28px;
    background-size: 18px 18px;
    background-position: left center;
    background-repeat: no-repeat;
    top: 30%;
    transform: translateY(-30%);

    &:hover {
      opacity: 1;
    }
  }

  .-heading {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    width: 100%;
    position: relative;
    margin-bottom: 0;

    &.--w_border {
      padding-bottom: $spacing_sm;
      margin-bottom: $spacing_md;
      border-bottom: 1px solid var(--border_default);
      color: #000;
    }

    &.--w_border .-heading_link {
      top: 20%;
      transform: translateY(-20%);      
      background-size: 22px 22px;
    }

    &:hover .-heading_link {
      opacity: 1;
    }

    h2.-heading_text {
      color: var(--font_primary);
      margin-bottom: 0;
    }

    h3.-heading_text {
      margin-bottom: 0;
    }
  }


  h1:not(.-page_title), h2, h3, h4, h5, h6, .-heading {
    margin-top: $spacing_lg;
    margin-bottom: $spacing_sm;

    // Remove top margin if heading is the first child
    &:first-of-type {
      margin-top: 0;
    }
  }

  h4 {
    color: var(--font_accent);
  }

  h5 {
    color: var(--caption_color);
    text-transform: uppercase;
    font-weight: var(--weight_regular);
  }

  img, iframe {
    display: block; // Ensure block display for images and iframes
    margin: $spacing_lg 0;
  }

  // Special case: if an image or iframe is followed by a paragraph or heading
  img + p,
  img + h1, img + h2, img + h3, img + h4, img + h5, img + h6,
  iframe + p,
  iframe + h1, iframe + h2, iframe + h3, iframe + h4, iframe + h5, iframe + h6 {
    margin-top: $spacing_md;
  }

  // Adjust spacing for nested elements within lists
  ul,  ol {
    margin-bottom: $spacing_md;
    padding-left: $spacing_md;

    ul {
      padding-top: $spacing_sm;
    }

    li {
      margin-bottom: $spacing_sm;
      list-style-type: circle;

      &::marker {
        color: var(--bg_accent);
      }
    }
  }

  // Blockquotes
  blockquote {
    margin: $spacing_lg 0;
    padding: $spacing_md $spacing_md;
    border-left: 4px solid var(--bg_accent);
    background: var(--bg_secondary);

    p {
      margin-bottom: $spacing_sm;
      font-family: var(--font_code);
      @include body_text_small;
      font-family: var(--font_code);
      font-style: normal;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // Code Blocks and Inline Code
  code {
    padding: 2px 4px;
    background-color: var(--code_inline_bg);
    color: var(--code_inline_text);
    border-radius: 3px;
  }

  pre {
    margin-bottom: $spacing_lg;
    padding: $spacing_md;
    background-color: var(--code_block_bg);
    color: var(--code_block_text);
    border-radius: 3px;
    overflow-x: auto;

    code {
      @include code_block_body_text;
      color: var(--code_block_text);
      line-height: 1.8;
      padding: 0; // Remove padding inside pre code block
      background-color: transparent;
    }
  }

  // Tables
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: $spacing_md;
  }

  th, td {
    padding: $spacing_sm $spacing_md;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f5f5f5;
    font-weight: bold;
  }

  // Links inside the page
  a {
    color: var(--font_link);
    text-decoration: underline;

    &:hover, &:focus {
      color: var(--font_link);
      text-decoration: underline;
    }
  }

  hr {
    border: 0;
    border-top: 1px solid var(--border_default);
    margin: $spacing_sm 0;
  }

  .-footnote {
    @include body_text_small;
    color: var(--font_accent);
  }  
}
