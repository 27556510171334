:root {
  /* Default Theme */

  /* Background Colors */
  --bg_primary: #ffffff; /* White for Body Background */
  --bg_secondary: #f7f9fc; /* Light Blue for Input Background */
  --bg_accent: #2b4977; /* Dark Blue for Accent Background */
  --code_editor_bg: #edf5ff; /* Dark Blue for Code Editor Background */
  --code_editor_bg_alt: #495667; /* Dark Gray-Blue for Nested Elements in Code Editor */

  /* Font Colors */
  --font_primary: #000000; /* Black for Main Text */
  --font_secondary: #2b4977; /* Dark Blue for Secondary Text (Headings, Labels, Captions) */
  --font_accent: #495667; /* Gray-Blue for Input Text and Labels */
  --font_primary_hover: #ffffff; /* White for Hover State on Main Text */

  --font_link: #2b4977; /* Dark Blue for Links */
  --important-message: #bf616a;

  /* Border Colors */
  --border_default: #E4E8EE; /* Gray-Blue for Default Borders */
  --border_hover: #888888; /* Light Gray for Hover Borders */

  /* Line Colors */
  --line_color: #495667; /* Gray-Blue for Default Lines */
  --line_hover_color: #555555; /* Slightly Darker Gray for Hover Lines */

  --code_inline_bg: #f7f9fc;
  --code_inline_text: #2B4977;

  --code_block_bg: #f7f9fc;
  --code_block_text: #4A4E69;
  --code_block_important: #2B4977;
  --code_block_comment: #403D39;
  --code_block_comment_highlight: #bf616a;
  --code_block_selector_bg: #001141;
  --code_block_selector_text: #edf5ff;
  

  /* Functional Colors (Captions, Credits, Labels) */
  --caption_color: #2b4977; /* Dark Blue for Captions and Credits */
  --label_color: #495667; /* Gray-Blue for Labels */

  /* Typography */
  /* Font Families */
  --font_heading: 'Source Sans 3', Arial, sans-serif;
  --font_nav: 'Source Sans 3', Arial, sans-serif;
  --font_label: 'Source Sans 3', Arial, sans-serif;
  --font_body: 'IBM Plex Sans', Georgia, serif;
  --font_strong: 'IBM Plex Sans', Georgia, serif;
  --font_function: 'Fira Code', monospace;
  --font_code: 'Fira Code', monospace;
  --font_bash: 'Fira Code', monospace;
  --font_input: 'Fira Code', monospace;
  --font_pre: 'Fira Code', monospace;

  /* Font Weights */
  --weight_regular: 400;
  --weight_semibold: 600;
  --weight_bold: 600;
  --weight_500: 500;

  /* Typography */
  --primary_font: "EB Garamond", serif;
  --secondary_font: "IBM Plex Serif", serif;
  --font_weight_normal: 400;
  --font_weight_bold: 700;

  
  --h1_font_size: 2.25rem;
  --h1_line_height: 2.5875rem;
  --h1_letter_spacing: 0.02em;

  --h1_font_size_large: 2.5rem;
  --h1_line_height_large: 2.8375rem;
  --h1_letter_spacing_large: 0.02em;


  --h2_font_size: 1.8rem;
  --h2_line_height: 2.1375rem;
  --h2_letter_spacing: 0.02em;

  --h3_font_size: 1.40rem;
  --h3_line_height: 1.7rem;
  --h3_letter_spacing: 0.02em;

  --h4_font_size: 1.35rem;
  --h4_line_height: 1.575rem;
  --h4_letter_spacing: 0.015em;

  --h5_font_size: 1.125rem;
  --h5_line_height: 1.35rem;
  --h5_letter_spacing: 0.015em;

  --h6_font_size: 0.9rem;
  --h6_line_height: 1.125rem;
  --h6_letter_spacing: 0.01em;

  --body_font_size: 1.035rem;
  --body_line_height: 1.5075rem;
  --body_letter_spacing: 0.01em;

  --body_large_font_size: 1.35rem;
  --body_large_line_height: 2.025rem;
  --body_large_letter_spacing: 0.01em;

  --body_small_font_size: 1.0rem;
  --body_small_line_height: 1.5rem;
  --body_small_letter_spacing: 0.01em;

  --code_block_font_size: 0.9rem;
  --code_block_line_height: 1.5rem;
  --code_block_letter_spacing: 0.05em;

  --small_font_size: 0.8rem;
  --small_line_height: 1.175rem;
  --small_letter_spacing: 0.01em;

  --caption_font_size: 0.84375rem;
  --caption_line_height: 1.125rem;
  --caption_letter_spacing: 0.005em;

  /* Container Defaults 
  --container_max_width: 1200px;
  --wide_container_max_width: 1600px;
  --narrow_container_max_width: 800px;
  --flushed_container_max_width: 100%;
  --container_padding: 20px;
  --container_margin: 0 auto;
  --wide_container_margin: 40px;
  */
}
