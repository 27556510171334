.context\[search\] {

  &.matrix\[page\]{

    .document_cards {
      @include mt(5);
      display: grid;
      gap: $spacing_sm;
    }

    .-no_results {
      @include mt(5);
      @include body_text;
      color: var(--font_secondary);
    }
  }
}