.anchors {
  background: var(--bg_secondary);
  border-radius: 4px;
  @include p(6);
  @include mb(10);

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0 !important;
    margin-bottom: 0 !important;
  }

  ul li {
    margin: 0;
    padding: 0;
    margin-bottom: $spacing_sm;
    list-style-type: none;

    &:before {
      content: "#";
      color: var(--code_editor_bg);
      @include code_editor_text;
      @include mr(2);
    }
  }

  .-h1_anchor,
  .-h2_anchor,
  .-h3_anchor,
  .-h4_anchor {
    a {
      color: var(--font_primary);
      font-weight: var(--weight_regular);
    }
  }

  .-h1_anchor {
    a {
      @include body_text_large;
    }
  }

  .-h1_anchor,
  .-h2_anchor {
    a {
      font-weight: var(--weight_semibold);
    }
  }
  
  .-h3_anchor {
    @include ml(5);
  }
  
  .-h4_anchor {
    @include ml(10);
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}