.context\[page_header\] {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-end; // Changed from 'end' for clarity
  align-items: flex-end; // Aligns items to the right
  width: 100%;
  @include mb(10);

  @media screen and (min-width: $screen_md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center; // Centers items vertically
    align-self: auto; // Ensure it doesn't conflict with align-items
  }

  > :first-child {
    align-self: flex-start;
  }
  
  > :nth-child(2) {
    align-self: flex-end;
  }
}