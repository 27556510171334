.site_header {

  .-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (min-width: $screen_lg) {
      margin-top: $container_padding;
      padding-left: $container_padding;
      padding-right: $container_padding;
      min-width: 260px;
      width: 260px;
    }

    @media screen and (min-width: $screen_xl) {
      min-width: 280px;
      width: 280px;
    }

    @media (min-width: $screen_xxl) {
      @include pl(20);
    }
  }

  .-identity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--border_default);

    @media (min-width: $screen_lg) {
      position: relative;
      border-bottom: none;
      @include mb(10);
    }    
  }
  
  .-identity_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: $container_padding;
    padding-right: $container_padding;
    padding-top: calc($container_padding / 2);
    padding-bottom: calc($container_padding / 2);

    @media (min-width: $screen_lg) {
      background: transparent;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .-logo {
    display: flex;
    justify-content: center;
    align-items: center;


    svg {
      max-width: 140px;
      @media (min-width: $screen_lg) {
        max-width: 100%;
      }
    }
    

  }

  .-menu_toggle:not(.--expanded) {
    .-icon.--expanded {
      display: none;
    }
  }

  .-menu_toggle.--expanded {
    .-icon.--collapsed {
      display: none;
    }
  }

  .-menu_toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;    
    
    .-icon {
      display: flex;
      justify-content: center;
      align-items: center;

    }

    svg {
      max-width: 32px;
      height: auto;
    }

    svg  {
      fill: var(--label_color);
    }

    @media (min-width: $screen_lg) {
      display: none;
    }
  }  

  .-content {
    padding-left: $container_padding;
    padding-right: $container_padding;
    padding-top: $container_padding;

    @media (min-width: $screen_lg) {
      padding: 0;
    }

    &.--hidden {
      display: none;
    }
  }

  .-info {
    @include mt(10);
    @include mb(10);
    border: 1px solid transparent;
    background-color: var(--bg_primary);
    padding-left: $container_padding;
    padding-right: $container_padding;
    padding-top: calc($container_padding / 2);
    padding-bottom: calc($container_padding / 2);
    @include footer_text;

    @media (min-width: $screen_lg) {
      @include mt(10);
    }

    @media (min-width: $screen_lg) {
      background-color: var(--bg_primary);
    }

    p {
      color: var(--label_color);
      @include footer_text;
    }
  }  

  .-blocktail_guide {
    display: flex;
    justify-content: start;
    @include mb(10);

    @media (min-width: $screen_lg) {
      display: none;
    }

  }

  .-external_link {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    color: var(--label_color);
    @include body_text_small;
    font-weight: 600;
      

    svg {
      max-width: 18px;
      height: auto;
      fill: var(--label_color);
    }

  }
}