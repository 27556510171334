.document_card {
  background: var(--bg_secondary);
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  max-width: 350px;
  border-radius: 4px;
  padding: calc($container_padding / 2);
  text-decoration: none;
  border: 1px solid var(--bg_secondary);

  .-title {
    @include m(0);
    @include p(0);
    @include body_text_small;
    color: var(--font_secondary);
    font-family: var(--font_code);
    margin-bottom: 0 !important;
  }

  .-file_icon {
    max-width: 45px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &:hover {
    .-title {
      /* transition to right by 5px smoothly */
      transition: margin-right 0.3s ease;
      margin-right: 5px;
    }
  }
}