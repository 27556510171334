.nav_group {
  @include mb(10);

  .-label {
    color: var(--label_color);
    @include h6_text;
    @include small_text_small;
    text-transform: uppercase;
    @include mb(2);
      a {
      text-decoration: none;
      }
  }

  .-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .-item {
    list-style: none;
  }

  .-link {
    color: var(--link_color);
    text-decoration: none;
    font-weight: var(--weight_regular);
    @include body_text_small;
    border-left: 2px solid transparent;
    @include pl(2);
    @include ml(0.5);
    display: block;

    &:hover {
      color: var(--link_hover_color);
    }
  }

  .-link.--active,
  .-link:hover {
    color: var(--font_secondary);
    border-left-color: var(--font_secondary);
  }

  .-link.--active {
    font-weight: var(--weight_semibold);
  }

}