/* Normalize CSS */
@import '../../node_modules/normalize.css/normalize.css';

/* Reset and Base Configuration */
@import 'config/index';
/* Establishes browser reset styles and foundational structural and styling settings */

/* Styling and Structure Libraries */
@import 'styling/index';    // Library for visual styling properties
@import 'structure/index';  // Library for layout and structural properties

/* Blocktail Methodology */
@import 'matrix--page';
@import 'context--page_header';
@import 'context--search';

@import 'blocks/index';

/* UI Agents */
.ui--highlight {
  color: var(--important-message);
}