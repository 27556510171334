/* Typography Mixins */

/* Page Titles */
@mixin page_title {
  font-family: var(--font_heading);
  font-size: 2.8rem;
  line-height: 1.12;
  font-weight: 100;
  letter-spacing: -0.9;
}

/* Headings */
@mixin h1_text {
  font-family: var(--font_heading);
  font-size: var(--h1_font_size);
  line-height: var(--h1_line_height);
  font-weight: var(--weight_bold);
  letter-spacing: var(--h1_letter_spacing);
}

@mixin h2_text {
  font-family: var(--font_heading);
  font-size: var(--h2_font_size);
  line-height: var(--h2_line_height);
  font-weight: var(--weight_bold);
  letter-spacing: var(--h2_letter_spacing);
}

@mixin h3_text {
  font-family: var(--font_heading);
  font-size: var(--h3_font_size);
  line-height: var(--h3_line_height);
  font-weight: var(--weight_bold);
  letter-spacing: var(--h3_letter_spacing);
}

@mixin h4_text {
  font-family: var(--font_heading);
  font-size: var(--h4_font_size);
  line-height: var(--h4_line_height);
  font-weight: var(--weight_bold);
  letter-spacing: var(--h4_letter_spacing);
}

@mixin h5_text {
  font-family: var(--font_heading);
  font-size: var(--h5_font_size);
  line-height: var(--h5_line_height);
  font-weight: var(--weight_regular);
  letter-spacing: var(--h5_letter_spacing);
}

@mixin h6_text {
  font-family: var(--font_heading);
  font-size: var(--h6_font_size);
  line-height: var(--h6_line_height);
  font-weight: var(--weight_regular);
  letter-spacing: var(--h6_letter_spacing);
}

/* Body Text */
@mixin body_text {
  font-family: var(--font_body);
  font-size: var(--body_font_size);
  line-height: var(--body_line_height);
  font-weight: var(--weight_regular);
  letter-spacing: var(--body_letter_spacing);
}

@mixin body_text_large {
  font-family: var(--font_body);
  font-size: var(--body_large_font_size);
  line-height: var(--body_large_line_height);
  font-weight: var(--weight_regular);
  letter-spacing: var(--body_large_letter_spacing);
}

@mixin body_text_small {
  font-family: var(--font_body);
  font-size: var(--body_small_font_size);
  line-height: var(--body_small_line_height);
  font-weight: var(--weight_regular);
  letter-spacing: var(--body_small_letter_spacing);
}

@mixin code_block_body_text {
  font-family: var(--font_code);
  font-size: var(--code_block_font_size);
  line-height: var(--code_block_line_height);
  font-weight: var(--weight_regular);
  letter-spacing: var(--code_block_letter_spacing);
}

/* Small Text */
@mixin small_text_small {
  font-family: var(--font_body);
  font-size: var(--small_font_size);
  line-height: var(--small_line_height);
  font-weight: var(--weight_regular);
  letter-spacing: var(--small_letter_spacing);
}

/* Captions */
@mixin caption_text {
  font-family: var(--font_body);
  font-size: var(--caption_font_size);
  line-height: var(--caption_line_height);
  font-weight: var(--weight_regular);
  letter-spacing: var(--caption_letter_spacing);
}

/* Links */
@mixin link_text {
  font-family: var(--font_body);
  text-decoration: underline;
  letter-spacing: var(--body_letter_spacing);
  
  &:hover {
    text-decoration: none;
  }
}

/* Footer */
@mixin footer_text {
  font-family: var(--font_body);
  font-size: var(--small_font_size);
  line-height: var(--small_line_height);
  font-weight: var(--weight_regular);
  letter-spacing: var(--small_letter_spacing);
}

/* Code Editor */
@mixin code_editor_text {
  font-family: var(--font_code);
  font-weight: var(--weight_regular);
  list-style: none;
}

@mixin code_editor_function {
  font-family: var(--font_function);
  font-weight: var(--weight_semibold);
  color: var(--code_function_color);
}

@mixin code_editor_bash {
  font-family: var(--font_bash);
  font-weight: var(--weight_regular);
}

@mixin code_editor_pre {
  font-family: var(--font_pre);
  font-weight: var(--weight_regular);
}

/* Labels */
@mixin label_text {
  font-family: var(--font_label);
  font-weight: var(--weight_semibold);
  letter-spacing: var(--body_letter_spacing);
}

/* Applying Mixins to HTML Elements */
body {
  @include body_text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @include h1_text;
}

h2 {
  @include h2_text;
}

h3 {
  @include h3_text;
}

h4 {
  @include h4_text;
}

h5 {
  @include h5_text;
}

h6 {
  @include h6_text;
}

p {
  @include body_text;
}

blockquote {
  @include body_text_large;
  font-style: italic;
}

figure {
  @include body_text;
}

figcaption {
  @include caption_text;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  @include link_text;
}

strong {
  font-weight: var(--weight_bold);
}

i {
  font-style: italic;
}

footer {
  @include footer_text;
}

/* Code and Preformatted Text */
code {
  @include code_editor_text;
}

pre {
  @include code_editor_pre;
}

.code_editor .function {
  @include code_editor_function;
}

.code_editor .bash {
  @include code_editor_bash;
}

/* Labels */
.label {
  @include label_text;
}


/* Color Variables */

/* Background and Text Colors */
code[class*="language-"],
pre[class*="language-"] {
  color: var(--code_block_comment);
  background: var(--code_block_bg);
  text-shadow: none;
}

.token.prolog,
.token.doctype,
.token.cdata,
.token.operator {
  color: var(--code_block_comment);
}

.token.comment,
.token.comment.block {
  color: var(--code_block_comment_highlight);
}

.token.punctuation {
  color: var(--code_block_comment);
}

.token.property,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.variable,
.token.deleted {
  color: var(--code_block_text);
}


.token.tag,
.token.attr-name,
.token.char,
.token.builtin,
.token.inserted {
  color: var(--code_block_text);
}

.token.class-name {
  color: var(--code_block_important);
}

.token.selector,
.token.attr-value,
.token.string {
  color: var(--code_block_important); /* Highlighting attr-value */
}

.token.attr-value,
.token.string {
  font-weight: var(--weight_500);
}

/* Line Numbers */
.line-numbers .line-numbers-rows {
  border-right: 1px solid var(--code_block_comment);
}

.line-numbers-rows > span:before {
  color: var(--code_block_comment);
}

/* Selection Background */
code[class*="language-"]::-moz-selection, 
code[class*="language-"] ::-moz-selection,
pre[class*="language-"]::-moz-selection, 
pre[class*="language-"] ::-moz-selection {
  background: var(--code_block_text);
  color: var(--code_block_bg);
}

code[class*="language-"]::selection, 
code[class*="language-"] ::selection,
pre[class*="language-"]::selection, 
pre[class*="language-"] ::selection {
  background: var(--code_block_text);
  color: var(--code_block_bg);
}


/* Adding spacing between lines */
pre[class*="language-"] {
  line-height: 2; /* Adjust this for vertical line spacing */
}

pre[class*="language-"] code {
  display: block;
  margin-bottom: 0.5em; /* Space between code blocks like divs */
}

code.language-css,
pre.language-css {
  .token.selector {
    font-weight: var(--weight_500);
  }
}