.blocktail_guide {
  @include pl(3);
  height: 100%;
  display: none;

  @media screen and (min-width: $screen_md) {
    display: block;
    border-left: 1px solid var(--bg_secondary);
  }

  .external_cta {
    background: var(--bg_accent);
    color: var(--code_block_bg);
    @include px(4);
    padding-top: 10px;
    padding-bottom: 10px;
    @include code_block_body_text;
    border-radius: 3px;
    max-width: 180px;
    text-decoration: none;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  
    svg {
      max-width: 18px;
      height: auto;
      fill: var(--code_block_bg);
    }
  }
}