/* Transitions */

/* Fade Transition */
@mixin transition_fade($duration: 0.3s, $timing: ease-in-out) {
  transition: opacity $duration $timing;
}

/* Slide Transition */
@mixin transition_slide($duration: 0.3s, $timing: ease-in-out) {
  transition: transform $duration $timing;
}

/* Color Transition */
@mixin transition_color($duration: 0.3s, $timing: ease-in-out) {
  transition: color $duration $timing, background-color $duration $timing;
}

/* Border Transition */
@mixin transition_border($duration: 0.3s, $timing: ease-in-out) {
  transition: border-color $duration $timing;
}

/* Apply Transitions to Common Elements */
a, button {
  @include transition_color;
}

.input, .border {
  @include transition_border;
}

/* Font Color Transition */
@mixin transition_font_color($duration: 0.3s, $timing: ease-in-out) {
  transition: color $duration $timing;
}

/* Background Color Transition */
@mixin transition_bg_color($duration: 0.3s, $timing: ease-in-out) {
  transition: background-color $duration $timing;
}

/* Border Radius Transition */
@mixin transition_border_radius($duration: 0.3s, $timing: ease-in-out) {
  transition: border-radius $duration $timing;
}
